@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --raleway-font: var(--font-raleway);
  --lora-font: var(--font-lora);
}

.font-sans {
  font-family: var(--raleway-font), sans-serif;
  font-weight: normal;
}
.font-serif {
  font-family: var(--lora-font), serif;
  font-weight: normal;
}

body {
  @apply font-serif font-normal text-neutral-800 md:text-base lg:text-xl;

  a {
    @apply no-underline;
  }

  p {
    @apply mb-4;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-sans font-bold tracking-normal;
}
h1 {
  @apply text-3xl md:text-4xl lg:text-5xl text-color-orange-500 mb-2;
}
h2 {
  @apply text-2xl md:text-4xl lg:text-5xl font-bold text-color-brown-800;
}
h3 {
  @apply text-xl md:text-2xl lg:text-3xl mb-2;
}
h4 {
  @apply text-xl md:text-2xl lg:text-3xl mb-2;
}
h5 {
  @apply text-lg md:text-xl lg:text-2xl mb-2;
}
h6 {
  @apply text-base md:text-lg lg:text-xl mb-2;
}

body .content a {
  @apply underline;
}

header {
  .logo-transition {
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  }

  .logo-dark-mode {
    transform: scale(0.9); /* Example transformation, adjust as needed */
    opacity: 0.8; /* Example opacity change, adjust as needed */
  }

  .navbar-dark {
    @apply text-white #{!important};
  }
}

/*================== NAVIGATION MENU ==================*/

.NavigationMenu {
  @apply font-sans;

  .NavigationMenuRoot {
    position: relative;
    display: flex;
    justify-content: center;
    z-index: 1;
  }

  .NavigationMenuList {
    display: flex;
    justify-content: center;
    padding: 4px;
    border-radius: 6px;
    list-style: none;
    margin: 0;
  }

  .NavigationMenuTrigger,
  .NavigationMenuLink {
    @apply text-black text-xl font-medium px-3;
    outline: none;
    user-select: none;
    line-height: 1;
  }
  .NavigationMenuTrigger:hover,
  .NavigationMenuLink:hover {
    @apply text-color-orange-700;
  }

  .NavigationMenuTrigger:focus,
  .NavigationMenuLink:focus {
    box-shadow: 0 0 0 2px var(--violet-7);
  }
  .NavigationMenuTrigger:hover,
  .NavigationMenuLink:hover {
    background-color: var(--violet-3);
  }

  .NavigationMenuTrigger,
  .NavigationMenuLink {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @apply text-black;
  }

  .NavigationMenuLink {
    display: block;
    text-decoration: none;
    font-size: 12px;
    line-height: 1;
  }

  .NavigationMenuContent {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    animation-duration: 250ms;
    animation-timing-function: ease;
  }
  .NavigationMenuContent[data-motion="from-start"] {
    animation-name: enterFromLeft;
  }
  .NavigationMenuContent[data-motion="from-end"] {
    animation-name: enterFromRight;
  }
  .NavigationMenuContent[data-motion="to-start"] {
    animation-name: exitToLeft;
  }
  .NavigationMenuContent[data-motion="to-end"] {
    animation-name: exitToRight;
  }
  @media only screen and (min-width: 650px) {
    .NavigationMenuContent {
      width: auto;
    }
  }

  .NavigationMenuIndicator {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 10px;
    top: 100%;
    overflow: hidden;
    z-index: 1;
    transition: width, transform 250ms ease;
  }
  .NavigationMenuIndicator[data-state="visible"] {
    animation: fadeIn 200ms ease;
  }
  .NavigationMenuIndicator[data-state="hidden"] {
    animation: fadeOut 200ms ease;
  }

  .NavigationMenuViewport {
    position: relative;
    transform-origin: top center;
    margin-top: 10px;
    width: 100%;
    background-color: white;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
      hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    height: var(--radix-navigation-menu-viewport-height);
    transition: width, height, 300ms ease;
  }
  .NavigationMenuViewport[data-state="open"] {
    animation: scaleIn 200ms ease;
  }
  .NavigationMenuViewport[data-state="closed"] {
    animation: scaleOut 200ms ease;
  }
  @media only screen and (min-width: 650px) {
    .NavigationMenuViewport {
      width: var(--radix-navigation-menu-viewport-width);
    }
  }

  .List {
    @apply grid p-6 m-0 gap-x-10 list-none;
  }

  .list {
    @apply space-x-0;
  }
  @media only screen and (min-width: 650px) {
    .List.one {
      width: 650px;
      grid-template-columns: 1fr 1fr;
    }
    .List.two {
      width: 650px;
      grid-auto-flow: column;
      grid-template-rows: repeat(3, 1fr);
    }
  }

  .ListItemLink {
    display: block;
    outline: none;
    text-decoration: none;
    user-select: none;
    padding: 12px;
    border-radius: 6px;
    font-size: 15px;
    line-height: 1;
  }
  .ListItemLink:focus {
    box-shadow: 0 0 0 2px var(--violet-7);
  }
  .ListItemLink:hover {
    background-color: var(--mauve-3);
  }

  .ListItemHeading {
    @apply text-black text-xl;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 5px;
    color: var(--violet-12);
  }

  .ListItemText {
    color: var(--mauve-11);
    line-height: 1.4;
    font-weight: initial;
  }

  .Callout {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      135deg,
      var(--purple-9) 0%,
      var(--indigo-9) 100%
    );
    border-radius: 6px;
    padding: 25px;
    text-decoration: none;
    outline: none;
    user-select: none;
  }
  .Callout:focus {
    box-shadow: 0 0 0 2px var(--violet-7);
  }

  .CalloutHeading {
    color2: white;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.2;
    margin-top: 16px;
    margin-bottom: 7px;
  }

  .CalloutText {
    color: var(--mauve-4);
    font-size: 14px;
    line-height: 1.3;
  }

  .ViewportPosition {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    top: 100%;
    left: 0;
    perspective: 2000px;
  }

  .CaretDown {
    position: relative;
    color: var(--violet-10);
    top: 1px;
    transition: transform 250ms ease;
  }
  [data-state="open"] > .CaretDown {
    transform: rotate(-180deg);
  }

  .Arrow {
    position: relative;
    top: 70%;
    background-color: white;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    border-top-left-radius: 2px;
  }

  @keyframes enterFromRight {
    from {
      opacity: 0;
      transform: translateX(200px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes enterFromLeft {
    from {
      opacity: 0;
      transform: translateX(-200px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes exitToRight {
    from {
      opacity: 1;
      transform: translateX(0);
    }
    to {
      opacity: 0;
      transform: translateX(200px);
    }
  }

  @keyframes exitToLeft {
    from {
      opacity: 1;
      transform: translateX(0);
    }
    to {
      opacity: 0;
      transform: translateX(-200px);
    }
  }

  @keyframes scaleIn {
    from {
      opacity: 0;
      transform: rotateX(-30deg) scale(0.9);
    }
    to {
      opacity: 1;
      transform: rotateX(0deg) scale(1);
    }
  }

  @keyframes scaleOut {
    from {
      opacity: 1;
      transform: rotateX(0deg) scale(1);
    }
    to {
      opacity: 0;
      transform: rotateX(-10deg) scale(0.95);
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
}

/* ================== Sidebar CSS ================== */
.sidebar {
  @apply shrink-0  h-full overflow-auto text-left text-lg text-black p-0 m-0;

  .sidebar-item {
    @apply block transition-colors rounded-md py-1 px-4 text-lg duration-150 m-0;
  }

  .sidebar-item:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  .sidebar-title {
    @apply flex;
  }

  .sidebar-title span i {
    display: inline-block;
    width: 1.5em;
  }

  .sidebar-title .toggle-btn {
    cursor: pointer;
    transition: transform 0.3s;
  }

  .sidebar-item.open > .sidebar-title .toggle-btn {
    transform: rotate(180deg);
  }

  .sidebar-content {
    /* padding: 0.25em 15px 0.25em 15px; */ /* Adjusted padding for consistency */
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
  }

  .sidebar-item.open > .sidebar-content {
    max-height: 500px; /* Adjust this value based on your submenu content size */
  }
  .btn {
    @apply px-4 py-2 mt-4 text-white bg-blue-500 rounded-md hover:bg-blue-700;
  }

  .sidebar-item.plain {
    text-decoration: none;
  }

  .sidebar-item.plain:hover {
    text-decoration: underline;
  }

  .sidebar-item.plain i {
    display: inline-block;
    width: 1.7em;
  }
}

/* ================== CUSTOM CSS ================== */
nav .navbar-menu a {
  @apply font-sans font-medium;
}
.slider-sec {
  @apply bg-cover bg-no-repeat bg-center relative overflow-hidden;
}
.slider-sec:before {
  @apply bg-fixed absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden;
}

.title-heading {
  @apply bg-cover bg-no-repeat bg-center relative overflow-hidden w-full;
}
.title-heading:before {
  @apply bg-fixed absolute bg-neutral-900 bg-opacity-10 bottom-0 left-0 right-0 top-0 w-full overflow-hidden transform;
}

.parallex1 {
  @apply bg-cover bg-fixed bg-no-repeat bg-center relative overflow-hidden w-full;
}
.parallex1:before {
  @apply absolute bottom-0 left-0 right-0 top-0 w-full overflow-hidden transform;
}

.col-effect1 {
  @apply bg-cover bg-no-repeat bg-center relative overflow-hidden p-8 pt-56 md:pt-[450px];
}
.col-effect1:before {
  @apply bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden;
}

.col-effect {
  @apply bg-cover bg-no-repeat bg-center relative overflow-hidden p-8 pt-56 md:pt-64 rounded-tr-3xl rounded-tl-[5rem] rounded-br-[5rem] rounded-bl-3xl;
}
.col-effect:before {
  @apply bg-gradient-to-b from-transparent to-neutral-800 bg-opacity-5 absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden;
}
.col-justify p {
  @apply text-justify;
}

.contact-box {
  @apply bg-orange-500 p-3 pb-0;
  .box-inside {
    @apply border border-gray-200 h-full p-4 md:p-6;
  }
}

.btn-theme1 {
  @apply font-medium text-black bg-white hover:bg-gray-900 hover:text-white py-4 px-4 lg:py-4 lg:px-10;
}
.btn-blue {
  @apply rounded font-medium text-white bg-blue-900 hover:bg-cyan-600 transition-all text-xs py-2 px-6;
}
.btn-red-lg {
  @apply font-medium text-white bg-red-600 hover:bg-red-800 transition-all uppercase py-4 px-4 lg:py-6 lg:px-8;
}
.btn-orange {
  @apply font-medium text-white bg-orange-600 hover:bg-orange-800 transition-all uppercase py-2 px-3;
}
.btn-red-sm {
  @apply font-medium text-white bg-red-600 hover:bg-red-800 transition-all text-sm py-2 px-4;
}
.btn-whoutline {
  @apply font-medium text-white border-2 border-white hover:bg-white w-full block transition-all text-sm uppercase text-center py-2 px-6;
}
.btn-blkline {
  @apply font-bold text-black hover:text-white border-2 border-black hover:bg-black transition-all uppercase py-4 px-4 lg:py-3 lg:px-10;
}

.accordion {
  @apply text-white border-none w-full;
  button {
    @apply bg-color-brown-900 border-neutral-600 hover:bg-transparent transition-all p-2    w-full text-left;
  }
  h2 {
    @apply text-lg text-color-brown-200 hover:text-color-orange-500 font-bold my-2 bg-transparent;
  }
  div,
  p {
    @apply text-slate-200;
  }
}

.accordion-features {
  button {
    @apply pl-5 border-0 border-l-4 border-l-color-blue-600 mt-3 p-1;
  }
  h2 {
    @apply text-xl font-normal text-black;
  }
  div,
  p {
    @apply text-base;
  }
}

.accordion-benefits {
  button {
    @apply py-2 pl-0 ml-0 bg-transparent;
  }
  h2 {
    @apply text-2xl font-semibold text-black;
  }
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.hide-scrollbar {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}

/* Underline styles - details of technique here: https://nickymeuleman.netlify.app/blog/css-animated-wrapping-underline */
.underlinestyle {
  a {
    background: linear-gradient(
        to right,
        rgba(100, 200, 200, 1),
        rgba(100, 200, 200, 1)
      ),
      linear-gradient(
        to right,
        rgba(255, 0, 0, 1),
        rgba(255, 0, 180, 1),
        rgba(0, 100, 200, 1)
      );
    background-size: 100% 0.1em, 0 0.1em;
    background-position: 100% 100%, 0 100%;
    background-repeat: no-repeat;
    transition: background-size 400ms;

    &:hover,
    &:focus {
      background-size: 0 0.1em, 100% 0.1em;
    }
  }
}
// ================== UNDERLINE CSS ==================

.underline {
  position: relative;
  overflow: hidden;

  a {
    position: relative;
    display: inline-block;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0.1em;
      background-color: hotpink;
      transform: scaleX(0);
      transform-origin: bottom left;
      opacity: 0; // Start with underline invisible
      transition: transform 300ms ease-out, opacity 300ms ease-out; // Smooth transition for transform and opacity
    }

    &:hover::after,
    &:focus::after {
      transform: scaleX(1); // Scale the underline back to full width
      opacity: 1; // Make the underline fully visible
    }
  }
}

/* ================== PRODUCT CSS HERE ================== */
.products {
  .product-items {
    @apply bg-zinc-950 border border-zinc-800;
    .product-details {
      @apply p-4 md:p-6;
      h6 {
        @apply text-white font-medium;
      }
    }
  }
}

/* ================== ANIMATED BORDER CSS ================== */
@keyframes myfirst {
  0% {
    background-position: 0 0, 100% 100%, 100% 0, 0 100%;
  }
  100% {
    background-position: 0 +100px, 100% 0%, 0 0, 100% 100%;
  }
}

footer {
  @apply text-gray-800;
  h6.title {
    @apply font-bold text-white mb-8 uppercase;
  }

  ul.bottommenu {
    @apply flex items-center justify-start font-semibold my-6;
    li {
      @apply mx-4;
      a {
        @apply border-b border-white px-4 py-2 uppercase;
      }
    }
  }
}

.fp-watermark {
  display: none;
}

/* styles/globals.css or a module.css file */
.custom-title {
  color: transparent;
  -moz-background-clip: text;
  -webkit-background-clip: text;
}

/* FadeInWhenVisible */
.reveal-text {
  position: relative;
  overflow: hidden; /* Ensures the content is clipped */
}

.reveal-text::after {
  content: "";
  position: absolute;
  top: -100%; /* Start from above */
  left: 0;
  right: 0;
  bottom: 0;
  background: white; /* Adjust the color based on your background */
  animation: slideReveal 0.5s ease-in-out forwards; /* Customize this animation */
}

@keyframes slideReveal {
  to {
    top: 100%;
  }
}

/* ServicesSection */
/* Assuming you're adding these inside a global CSS file */
.image-box {
  @apply overflow-hidden;
}

.img {
  @apply w-full h-full object-cover;
  transition: transform 1.2s cubic-bezier(0.17, 0.97, 0.38, 1);
}

.content-box {
  @apply px-20;
}

.title {
  @apply text-4xl font-bold mb-2;
}

.text {
  @apply opacity-60;
}

/* AnimationComponent */
/* Custom CSS for animations and styles not covered by Tailwind */
@keyframes blurFadeInOut {
  0% {
    opacity: 0;
    text-shadow: 0px 0px 40px #fff;
    transform: scale(1.3);
  }
  20%,
  75% {
    opacity: 1;
    text-shadow: 0px 0px 1px #fff;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    text-shadow: 0px 0px 50px #fff;
    transform: scale(0);
  }
}

@keyframes blurFadeIn {
  0% {
    opacity: 0;
    text-shadow: 0px 0px 40px #fff;
    transform: scale(1.3);
  }
  50% {
    opacity: 0.5;
    text-shadow: 0px 0px 10px #fff;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    text-shadow: 0px 0px 1px #fff;
    transform: scale(1);
  }
}

@keyframes fadeInBack {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    opacity: 0.4;
    transform: scale(2);
  }
  100% {
    opacity: 0.2;
    transform: scale(5);
  }
}

@keyframes fadeInRotate {
  0% {
    opacity: 0;
    transform: scale(0) rotate(360deg);
  }
  100% {
    opacity: 1;
    transform: scale(1) rotate(0deg);
  }
}

/**
.sp-container h2.frame-1 {
  animation: blurFadeInOut 3s ease-in 0s;
}

.sp-container h2.frame-2 {
  animation: blurFadeInOut 3s ease-in 3s;
}

.sp-container h2.frame-3 {
  animation: blurFadeInOut 3s ease-in 6s;
}

.sp-container h2.frame-4 {
  animation: blurFadeInOut 3s ease-in 9s;
}

.sp-container h2.frame-5 span {
  animation: blurFadeIn 3s ease-in 12s backwards;
  color: transparent;
  text-shadow: 0px 0px 1px #fff;
}

.sp-container h2.frame-5 span:nth-child(2) {
  animation-delay: 13s;
}

.sp-container h2.frame-5 span:nth-child(3) {
  animation-delay: 14s;
}

.sp-globe {
  animation: fadeInBack 3.6s linear 14s backwards;
}

.sp-circle-link {
  animation: fadeInRotate 1s linear 16s backwards;
}
**/
.accordion-content {
  transition: max-height 1s ease, opacity 0.5s ease;
  @apply bg-white;
}
.blog {
  @apply font-sans;
  h1 {
    @apply text-color-orange-800 mb-2;
  }
  h2 {
    @apply text-3xl md:text-4xl text-color-brown-800 mb-2 mt-6;
  }
  h3 {
    @apply text-2xl md:text-3xl text-color-brown-600;
  }
  h4 {
    @apply text-xl md:text-2xl text-color-brown-600;
  }
  .table-of-contents {
    @apply bg-color-orange-100 px-4 sm:px-10 py-1;
  }

  li,
  ul {
    @apply m-0 p-0;
  }

  .highlight {
    @apply px-4 sm:px-10 py-4 sm:py-8;

    h3 {
      @apply text-lg text-color-brown-600 mb-4 pt-0 mt-0 pb-4 border-b border-color-brown-700 uppercase font-bold;
    }

    &.highlight-brown {
      @apply bg-color-brown-100 text-base;

      &.darker {
        @apply bg-color-brown-200;
      }
      h3 {
        @apply text-color-brown-600;
      }
    }

    &.highlight-blue {
      @apply bg-color-blue-100 text-base;

      &.darker {
        @apply bg-color-blue-200;
      }
      h3 {
        @apply text-color-blue-600;
      }
    }
  }

  .faq {
    @apply bg-color-blue-100 px-4 sm:px-10 py-4;
    h3 {
      @apply text-lg;
    }
  }

  .image-prompt {
    display: none;
  }
}
